<script>
import Modal from "@/components/modal.vue";
import Axios from "@/plugins/axios";
import API from "@/plugins/axios-method";
import { helpers, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import {SAVING, TRAVEL, SCHOLARSHIP} from "@/helpers/constant/product";

export default {
  name: 'product-form-modal',
  components: {Modal},
  props: {
    title: {
      type: String,
      default: "Créer Un Produit"
    },
    value: {},
    activeProduct: {
      type: Object,
      default(){
        return null
      }
    },
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: false,
      name: null,
      description: null,
      subscriptionAmount: null,
      id: null,
      type: null,
      gcu: null,
      selectTypes: [
          {
            value: SAVING,
            text: 'Epargne'
          },
        {
          value: TRAVEL,
          text: 'Voyage'
        },
        {
          value: SCHOLARSHIP,
          text: 'Scolarité'
        },
      ],

      isSubmitting: false,
      isSubmitted: false,
    }
  },
  watch: {
    value(newValue) {
      this.visible = newValue
    },
    visible(newValue){
      this.visible = newValue
    },
    activeProduct(){
      if (this.activeProduct){
        this.id = this.activeProduct.uniqId
        this.name = this.activeProduct.name
        this.description = this.activeProduct.description
        this.subscriptionAmount = this.activeProduct.subscriptionAmount
        this.type = this.activeProduct.type
        this.gcu = this.activeProduct.gcu
      }
    }
  },
  methods: {
    onModalClose(){
      this.clearForm()
      this.visible = false
      this.isSubmitted = false
      this.isSubmitting = false
      this.$emit('onModalClose', 'product-form')
    },
    formSubmit(){
      if (this.activeProduct){
        this.updateProduct()
      }else{
        this.createProduct()
      }
    },
    createProduct(){
      if (this.validateForm()) return
      this.isSubmitted = true

      Axios.post('/products', {
        name: this.name,
        description: this.description,
        subscriptionAmount: this.subscriptionAmount,
        type: this.type,
        gcu: this.gcu
      })
          .then(response => {
            if (response.status === 201){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })

    },
    updateProduct(){
      if (this.validateForm()) return
      this.isSubmitted = true

      API.patch('/products/' + this.id, {
        name: this.name,
        description: this.description,
        subscriptionAmount: this.subscriptionAmount,
        type: this.type,
        gcu: this.gcu
      })
          .then(response => {
            if (response.status === 200){
              this.createSuccess(response.data)
            }
          })
          .catch(() => {
            this.isSubmitted = false
          })
    },
    createSuccess(p){
      this.isSubmitting = false
      this.isSubmitted = false
      this.clearForm()
      this.onModalClose()
      this.$emit('productCreated', p)
    },
    clearForm(){
      this.v$.$reset()
      this.name = null
      this.description = null
      this.subscriptionAmount = null
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  validations: {
    name: {
      required: helpers.withMessage("L'email est requis", required),
    },
    type: {
      required: helpers.withMessage("Le type est requis", required),
    },
    description: {
      required: helpers.withMessage("La description est requise", required),
    },
    subscriptionAmount: {
      required: helpers.withMessage("Le montant d'abonnement est requis", required),
    },
    gcu: {
      required: helpers.withMessage("Le lien des condition général est requis", required)
    }
  }
}
</script>

<template>
  <Modal :title="title" @closeModal="onModalClose" v-model="visible">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="telephone" class="form-label">Nom du produit</label>
          <b-form-input type="text" placeholder="Nom du produit" :class="{'is-invalid': v$.name.$errors.length }" v-model.trim="name"/>
          <div class="invalid-feedback" v-for="error in v$.name.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Type</label>
          <b-form-select :data-choice="true" :data-choices-sorting-false="true"  :class="{'is-invalid': v$.type.$errors.length }" v-model.trim="type" :options="selectTypes"/>
          <div class="invalid-feedback" v-for="error in v$.type.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Description</label>
          <b-form-textarea placeholder="Description" :class="{'is-invalid': v$.description.$errors.length }" v-model.trim="description"/>
          <div class="invalid-feedback" v-for="error in v$.description.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Montant de Souscription</label>
          <b-form-input type="number" placeholder="Montant Souscription" :class="{'is-invalid': v$.subscriptionAmount.$errors.length }" v-model.number="subscriptionAmount"/>
          <div class="invalid-feedback" v-for="error in v$.subscriptionAmount.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">GCU</label>
          <b-form-input type="url" placeholder="GCU" :class="{'is-invalid': v$.gcu.$errors.length }" v-model.trim="gcu"/>
          <div class="invalid-feedback" v-for="error in v$.gcu.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Enregistrer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style>

</style>