<script>
import API from "@/plugins/axios-method";
import {SAVING, SCHOLARSHIP, TRAVEL} from "@/helpers/constant/product";
import {inject} from "vue";

export default {
  name: 'product-table-card',
  components: {},
  props: {
    products: {
      type: Array,
      default(){
        return []
      }
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(){
    const globals = inject("globals");

    return {globals}
  },
  data(){
    return {
    }
  },
  methods: {
    updateStatus(id,status){

      const confirmation = confirm("Voulez-vous vraiment changer le statut de ce produit ?")

      if (!confirmation) return window.location.reload()

      API.patch(`/products/${id}/status`, {
        status: Number(!status)
      })
          .then(() => {

          })
          .catch(error => {
            console.log(error)
          })
    },
    openUpdateProductModal(p){
      this.$emit('openUpdateProductModal', p)
    },
    openDescriptionModal(p){
      this.$emit('openDescriptionModal', p)
    },
    getType(type){
      switch (type){
        case SAVING:
          return 'Epargne'
        case TRAVEL:
          return 'Voyage'
        case SCHOLARSHIP:
          return 'Scolarité'
      }
    }
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped align-middle table-nowrap mb-0">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nom</th>
        <th scope="col" >Description</th>
        <th scope="col">Montant Souscription (XAF)</th>
        <th scope="col">Type</th>
        <th scope="col">Statut</th>
        <th scope="col">CGU</th>
<!--        <th scope="col">Créé le</th>-->
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(p, index) in products" :key="index">
        <th scope="row">{{index + 1}}</th>
        <td>{{ p.name }}</td>
        <td>
          <a href="javascript:void(0);" role="button" @click="openDescriptionModal(p)" class="link-primary">Voir Description</a>
        </td>
        <td>{{ p.subscriptionAmount }}</td>
        <td>{{ getType(p.type) }}</td>
        <td>
          <div class="form-check form-switch">
            <input class="form-check-input" @change="updateStatus(p.uniqId, p.status)" type="checkbox" role="switch" :id="'SwitchCheck'+ index" :checked="p.status">
          </div>
        </td>
        <td>
          <a v-if="p.gcu" target="_blank" :href="p.gcu">CGU</a>
        </td>
        <td>
          <div class="hstack gap-3 fs-15">
              <a v-if="globals.roles.IS_SUPER_ADMIN" href="javascript:void(0);" role="button" @click="openUpdateProductModal(p)" class="link-primary"><i class="bx bx-pencil"></i></a>
              <router-link :to="'/admin/products/' + p.uniqId" class="link-primary">
                <i class="ri-eye-fill"></i>
              </router-link>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>